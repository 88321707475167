.dropdown {
  position: relative;
  background-color: rgb(55, 54, 54);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 10rem;
  border-radius: 5px;
  opacity: 0.9;
  overflow-y: scroll;
}

.dropdown_item {
  font-size: 1.1rem;
  color: white;
  font-weight: 500;
  padding: 0.3rem;
  border-bottom: 1px solid black;
  margin: 0;
}

.dropdown_item:hover {
  background-color: black;
}

.applied_dropdown_item {
  font-size: 1.1rem;
  color: white;
  font-weight: 500;
  padding: 0.3rem;
  border-bottom: 1px solid black;
  margin: 0.1rem;
  background-color: #000099;
  border-radius: 5;
}

.installation-photo {
  margin-left: 5px;
  max-width: 20%;
  margin-bottom: 5px;
  clear: both;
  border-radius: 5px;
}

.installation-photo:hover {
  border-radius: 5px;
  border: 2px solid #000099;
}

/* map styles */
.mapbox-improve-map {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}

/* toggle buttons styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000099;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
