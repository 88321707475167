@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.btn-secondary {
  background-color: #000099 !important;
  border-color: #000099 !important;
  border-radius: 0.2em;
  margin: 0.1em;
  padding: 0.2em;
}

.labels_card_style {
  background-color: transparent;
  border-color: transparent;
  overflow-y: scroll;
  max-height: 400;
  border-radius: 3;
  margin-left: 0;
  padding: 0 0;
}

.flex-row {
  width: 300px;
  display: flex;
  flex-direction: row;
  background: whitesmoke;
  height: 50px;
  padding: 10px 5px;
}

.box {
  background-color: darkmagenta;
  color: white;
  margin-right: 10px;
  height: 50px;
  width: 50px;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
}

.box--end {
  margin-left: auto;
}

.flex-row--reverse {
  flex-direction: row-reverse;
}

.flex-row--reverse .box--end {
  margin-right: auto;
  margin-left: 0;
}

.not-flex-row {
  display: flex !important;
  flex-wrap: nowrap;
  width: auto;
  align-items: baseline;
}

.info_label {
  margin: 1em;
  color: blue;
  max-width: 300;
  min-width: 100;
}

.plus_label {
  float: right;
}

.label_text_style {
  max-width: 300;
  min-width: 100;
  width: auto;
  padding: 8px 5px 3px 8px !important;
  margin: 0px 5px 10px 15px !important;
  font-size: 17px !important;
  font-weight: bold;
}

.account_title {
  align-self: center !important;
  font-weight: bolder !important;
  text-align: center !important;
  border: 33px solid !important;
  border-color: transparent !important;
}

.member-list {
  width: 35em;
  align-self: center;
  border: none !important;
  background-color: rgb(239, 247, 247) !important;
}

.list-group-item {
  font-weight: bold;
}

.bgcolor {
  background-color: rgb(239, 247, 247) !important;
  color: #5bc0f8 !important;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.drop_down_menu.show {
  background-color: aliceblue;
  border: none;
}

/* scroll bar*/
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  background-color: #f5f5f5;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6c757d;
}

.title {
  border: none;
  font-size: 18px;
  font-weight: 500;
}

.high_warning_level {
  color: rgb(0, 0, 153);
  font-weight: 500;
}

.dropdown-toggle::after {
  content: none !important;
}

.margin-top {
  margin-top: 10px !important;
}

a:link {
  text-decoration: none;
  color: #5bc0f8;
}

a:hover {
  text-decoration: none;
  color: #5bc0f8;
}

a {
  color: #212529 !important;
}

.input_underline {
  border: 0;
  outline: 0 !important;
  width: 70px;
  background: transparent;
  border-bottom: 1px solid #b8b8b8 !important;
}

.password-alert {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.end_align {
  float: right;
  background: #0081c9 !important;
  border: none;
  font-weight: 500;
}

.bi {
  color: white;
}
